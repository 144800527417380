.container {
  display: flex;
  flex-direction: column;
}

.inputs-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.image-container {
  display: flex;
  flex-direction: row;
  max-height: 50px;
}

.action-button {
  width: 100%;
  height: 200px;
}
