.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 25px;
}

.inputs-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.image-container {
  display: flex;
  flex-direction: row;
  max-height: 50px;
}

.recurring-pattern-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
