.teacher-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 10px;
  justify-content: center;

  p {
    margin: 0;
  }

  .location-name {
    font-size: 10px;
    line-height: 10px;
  }
}
.teacher-option-wrapper {
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.teacher-image {
  width: 55px;
  height: 55px;
  object-fit: cover;
  border-radius: 25px;
  margin-right: 5px;
}
.small-teacher-image {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 25px;
  margin-right: 5px;
}
.teacher-select {
  .MuiInputBase-root {
    min-height: 80px;
  }
}
