.center-container {
  display: flex;
  flex-direction: row;
  gap: 10px;

  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
