.image-input-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  .image {
    width: 100%;
    height: 100%;
  }

  .centered {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: auto;
    transform: translateY(-50%);

    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.filepond--file-info {
  display: none !important;
}

@media (min-width: 1024px) {
  .multiple-images {
    .filepond--item {
      width: calc(33% - 0.5em);
    }
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .multiple-images {
    .filepond--item {
      width: calc(50% - 0.5em);
    }
  }
}
