.stretchedRow {
    display: flex;
    align-items: stretch;
    width: 100%;
  }
  
  .item {
    padding: 16px; // equivalent to theme.spacing(2)
    text-align: center;
    flex-grow: 1;
  }
  
  .divider {
    height: auto; // Adjust as needed
    margin: 0 8px;
    width: 0.5px; // Increase width for visibility
    background-color: #e3e3e3; // Change color for visibility
  }