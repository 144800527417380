.input {
  flex: 1;
}
.autocomplete-input {
  z-index: 1000;
  position: absolute;
  background-color: white;
  left: 50px;
  top: 10px;
  right: 10px;
}
